.offline-section {
  padding: 1rem;
  background-color: #fa5300;
}

.offline-section > h2 {
  text-align: center;
  font-size: 4rem;
  text-transform: uppercase;
}
