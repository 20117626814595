$backgroundColor: #ebeff1;
$ncssAccent: #fa5300;
$ncssSuccess: #48bd1f;
$ncssError: #fe0000;
$ncssWarn: rgb(238, 180, 80);
$ncssBlack: #111;
$ncssWhite: #fff;
$ncssOffWhite: #f7f7f7;
$ncssGrey: #8d8d8d;
$ncssGreyMd: #dddddd;
$ncssGreyLight: #e5e5e5;
$ncssColumbiablue: #cee4e8;
$ncssDuststorm: #e1bdc4;
$maxBreakMd: 641px;
$ncssLinkColor: #3498db;
$veryDarkGray: #222222;
$borderDivide: 4px solid ncss-grey-md;
$boxShadowDivider: 0 0 5px rgba(0, 0, 0, 0.5);
$boxShadowFloat: 5px 5px 5px rgba(0, 0, 0, 0.1);
$btnBorderRadius: 4px;
$cardPadding: 1rem;
$headerGray: #f1f1f1;
$fontDefault: Nike TG, Helvetica, sans-serif;
$nikeFuel: #8ddf3d;
$blueGrey: #282c34;
