$ncssOffWhite: #f7f7f7;
$color-charcoal: #222222;
$ncssGreyLight: #e5e5e5;
$grid-size: 8px !default;
$cell-horizontal-padding: 10px;

// The rowHeight in grid properties has to be the same as $row-height
$row-height: $grid-size * 5;
$header-height: $grid-size * 5;

// Fonts
$font-Nike-TG: 'Nike TG';
$font-Robo: Roboto, sans-serif;

$letterSpace: 1px;

.ag-react-container {
  height: 100%;
}

.headerRow {
  color: $color-charcoal;
  text-transform: uppercase;
}

//Grid Highlights Row
.ag-center-cols-clipper .ag-row-hover {
  background-color: $ncssGreyLight;
}

.ag-header-row:nth-child(even) .ag-header-cell {
  background-color: $ncssOffWhite;
}

.ag-theme-material .ag-header .ag-pinned-left-header:after {
  content: '\E648';
  color: $color-charcoal;
  font-family: 'nike-glyphs';
  top: 10px;
  font-size: 1em;
  margin: 39.5%;
  position: relative;
}

@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-material/sass/ag-theme-material';
