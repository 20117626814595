@import 'Styles/constants.scss';

//body.light-mode {
//  background: $backgroundColor;
//  overflow: hidden;
//  margin: 0;
//  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//    sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//body.dark-mode {
//  filter: invert(85%) hue-rotate(180deg);
//  background: #202020;
//  overflow: hidden;
//  .footerContainer,
//  #header,
//  #adminPortalNav-unordered-list,
//  #progress-circular-determinate-inherit-failure,
//  #alertBox {
//    filter: invert(100%) hue-rotate(180deg); /* Undo the effect for already-dark components */
//  }
//}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
